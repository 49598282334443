import { ArrowRightMd, Button, Small, Stack } from '@construct-kit/core';
import styled from 'styled-components';
import { isPresent } from '../../utils/helpers';
import Status from '../Status';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import Panel from './Panel';
import { formatDateTime } from '../../utils/formatDate';
import { fetchJson } from '../../API/fetch';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  homepageDataQueryKey,
  HomepageResponseData,
  ReportingTasksResponse,
} from '../../pages/Home';
import ErrorAlert from '../ErrorAlert';
import { ButtonLoadingSpinner } from '../LoadingSpinner';
import { match } from 'ts-pattern';

export interface ReportingTasksPanelProps {
  reportingTasksData: ReportingTasksResponse;
}

const ReprocessReportingContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const reprocessReporting = async (): Promise<ReportingTasksResponse> =>
  await fetchJson<ReportingTasksResponse>('/reporting/reprocess', {
    method: 'PUT',
  });

const ReportingTasksPanel = ({
  reportingTasksData: { tasks, lastUpdated },
}: ReportingTasksPanelProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { isPending, isError, error, mutate } = useMutation({
    mutationFn: reprocessReporting,
    onSuccess: (reportingTasks) => {
      queryClient.setQueryData<HomepageResponseData>(
        homepageDataQueryKey,
        (oldHomepageData) => {
          if (!isPresent(oldHomepageData)) {
            return oldHomepageData;
          }

          return {
            ...oldHomepageData,
            reportingTasksData: reportingTasks,
          };
        },
      );
    },
  });

  return (
    <Panel heading="Reporting tasks for today">
      <Stack gap="medium">
        {isError && <ErrorAlert error={error} />}
        {tasks && (
          <Table>
            <TableHeader>
              <TableHeaderColumn>Task</TableHeaderColumn>
              <TableHeaderColumn>Status</TableHeaderColumn>
            </TableHeader>
            <TableBody>
              {tasks.length === 0 ? (
                <EmptyTableBody />
              ) : (
                tasks.map(({ task, status }) => (
                  <TableRow key={task}>
                    <TableContentColumn>
                      {match(task)
                        .with('fbReport', () => 'FB Report')
                        .with('yhReport', () => 'YH Report')
                        .with('afReport', () => 'AF Report')
                        .with('yhnReport', () => 'YHN Report')
                        .with('ttdReport', () => 'TTD Report')
                        .with('cleanData', () => 'Process Reporting')
                        .exhaustive()}
                    </TableContentColumn>
                    <TableContentColumn>
                      {isPresent(status) ? <Status status={status} /> : ''}
                    </TableContentColumn>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        )}
        {isPresent(lastUpdated) && (
          <Small>Last ran: {formatDateTime(lastUpdated)}</Small>
        )}
        <ReprocessReportingContainer>
          <Button
            variant="outline"
            sizeVariant="medium"
            fullWidth={true}
            disabled={isPending}
            icon={isPending ? <ButtonLoadingSpinner /> : <ArrowRightMd />}
            iconPlacement="right"
            onClick={() => mutate()}
          >
            Reprocess reporting
          </Button>
        </ReprocessReportingContainer>
      </Stack>
    </Panel>
  );
};

export default ReportingTasksPanel;
