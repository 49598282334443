import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { fetchJson } from '../../API/fetch';
import { isPresent } from '../../utils/helpers';
import ErrorAlert from '../ErrorAlert';
import FBAudienceApiDetailsModal from './FBAudienceApiDetailsModal';
import FBAudienceCountsTable from './FBAudienceCountsTable';
import { FacebookAudienceCountsResponse } from './types';
import { PageLoadingSpinner } from '../LoadingSpinner';
import { Strong, Text, Stack } from '@construct-kit/core';
import { formatDateTime } from '../../utils/formatDate';

const FBAudienceCountApi = (): JSX.Element => {
  const { isLoading, isError, error, data } = useQuery({
    queryKey: ['fbAudienceCountApi'],
    queryFn: () =>
      fetchJson<FacebookAudienceCountsResponse>(
        '/facebook-audience-api-counts',
      ),
  });

  const [detailsAccountId, setDetailsAccountId] = useState<string>();

  return (
    <>
      {isLoading && <PageLoadingSpinner />}
      {isError && <ErrorAlert error={error} />}
      {data && (
        <Stack>
          <Text>
            <Strong>Last updated:</Strong> {formatDateTime(data.lastUpdated)}
          </Text>
          <FBAudienceCountsTable
            data={data.audienceCounts}
            onAccountIdClick={setDetailsAccountId}
          />
        </Stack>
      )}
      <FBAudienceApiDetailsModal
        accountId={detailsAccountId}
        opened={isPresent(detailsAccountId)}
        onRequestClose={() => setDetailsAccountId(undefined)}
      />
    </>
  );
};

export default FBAudienceCountApi;
