import { Divider, H3, Stack, spacingHelper } from '@construct-kit/core';
import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledDivider = styled(Divider)`
  margin: ${spacingHelper('0 0 medium')};
`;

const TableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const StyledCard = styled.div`
  max-width: fit-content;
`;

interface BookingDataPanelProps {
  children: ReactNode;
  heading: string;
}

const BookingDataPanel = ({
  children,
  heading,
}: BookingDataPanelProps): JSX.Element => (
  <StyledCard>
    <Stack inset="large medium" gap="medium">
      <H3>{heading}</H3>
      <StyledDivider />
      <TableContainer>{children}</TableContainer>
    </Stack>
  </StyledCard>
);

export default BookingDataPanel;
