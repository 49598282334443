import { Link } from '@construct-kit/core';
import { ValidationWarningBooking } from '../../pages/UploadBookingCampaign';
import BookingsModal from './BookingsModal';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';

interface Props {
  bookings: ValidationWarningBooking[];
}

const PackageIdsWarningModal = ({ bookings }: Props): JSX.Element => (
  <BookingsModal heading="Package Ids">
    <Table>
      <TableHeader>
        <TableHeaderColumn>Booking ID</TableHeaderColumn>
        <TableHeaderColumn>Listing ID</TableHeaderColumn>
        <TableHeaderColumn>Package ID</TableHeaderColumn>
      </TableHeader>
      <TableBody>
        {bookings.length === 0 ? (
          <EmptyTableBody />
        ) : (
          bookings.map(({ rowNumber, bookingId, listingId, packageId }) => (
            <TableRow key={rowNumber}>
              <TableContentColumn>{bookingId}</TableContentColumn>
              <TableContentColumn>
                <Link
                  href={`https://www.realestate.com.au/${listingId}`}
                  target="_blank"
                >
                  {listingId}
                </Link>
              </TableContentColumn>
              <TableContentColumn>{packageId}</TableContentColumn>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  </BookingsModal>
);

export default PackageIdsWarningModal;
