import { Stack } from '@construct-kit/core';
import { isPresent } from '../../utils/helpers';
import BookingDataActiveJobsListPanel, {
  ActiveJobData,
} from './BookingDataActiveJobsListPanel';
import BookingDataBookingTrackerPanel, {
  BookingTrackerData,
} from './BookingDataBookingTrackerPanel';
import BookingDataCompletedJobsListPanel, {
  CompleteJobData,
} from './BookingDataCompletedJobsListPanel';
import BookingDataFbDailyCampaignReportingPanel, {
  FacebookReportingCampaignData,
} from './BookingDataFbDailyCampaignReportingPanel';
import BookingDataFbTotalCampaignReportingPanel, {
  FacebookReportingCampaignFlatData,
} from './BookingDataFbTotalCampaignReportingPanel';
import BookingDataGoLiveEmailsPanel, {
  BookingEmailData,
} from './BookingDataGoLiveEmailsPanel';
import BookingDataMmDailyCampaignReportingPanel, {
  MediamathReportingCampaignData,
} from './BookingDataMmDailyCampaignReportingPanel';
import BookingDataMmTotalCampaignReportingPanel, {
  MediamathReportingCampaignFlatData,
} from './BookingDataMmTotalCampaignReportingPanel';
import BookingDataOptimisationsListPanel, {
  OptimisationData,
} from './BookingDataOptimisationsListPanel';
import BookingDataRecognisedBookingsListPanel, {
  RecognizedBookingData,
} from './BookingDataRecognisedBookingsListPanel';
import BookingDataYhDailyCampaignReportingPanel, {
  YahooReportingCampaignData,
} from './BookingDataYhDailyCampaignReportingPanel';
import BookingDataYhTotalCampaignReportingPanel, {
  YahooReportingCampaignFlatData,
} from './BookingDataYhTotalCampaignReportingPanel';
import BookingDataAfDailyCampaignReportingPanel, {
  AdformReportingCampaignData,
} from './BookingDataAfDailyCampaignReportingPanel';
import BookingDataAfTotalCampaignReportingPanel, {
  AdformReportingCampaignFlatData,
} from './BookingDataAfTotalCampaignReportingPanel';
import BookingDataTbDailyCampaignReportingPanel, {
  TaboolaReportingCampaignData,
} from './BookingDataTbDailyCampaignReportingPanel';
import BookingDataTbTotalCampaignReportingPanel, {
  TaboolaReportingCampaignFlatData,
} from './BookingDataTbTotalCampaignReportingPanel';
import {
  BookingDataBoostBookingsListPanel,
  BoostBookingsData,
} from './BookingDataBoostBookingsListPanel';
import { useQueries, UseQueryResult } from '@tanstack/react-query';
import { fetchJson } from '../../API/fetch';
import ErrorAlert from '../ErrorAlert';
import { PageLoadingSpinner } from '../LoadingSpinner';
import BookingDataTtdDailyCampaignReportingPanel, {
  TradedeskReportingCampaignData,
} from './BookingDataTtdDailyCampaignReportingPanel';
import BookingDataTtdTotalCampaignReportingPanel, {
  TradedeskReportingCampaignFlatData,
} from './BookingDataTtdTotalCampaignReportingPanel';

interface BookingDataDetailsProps {
  bookingId: string;
}

export enum BookingDataSection {
  BookingTracker = 'bookingTracker',
  ActiveJobs = 'activeJobs',
  CompleteJobs = 'completeJobs',
  RecognizedBookings = 'recognizedBookings',
  BoostBookings = 'boostBookings',
  BookingEmails = 'bookingEmails',
  Optimisations = 'optimisations',
  FacebookReportingCampaigns = 'facebookReportingCampaigns',
  FacebookReportingCampaignFlats = 'facebookReportingCampaignFlats',
  MediamathReportingCampaigns = 'mediamathReportingCampaigns',
  MediamathReportingCampaignFlats = 'mediamathReportingCampaignFlats',
  YahooReportingCampaigns = 'yahooReportingCampaigns',
  YahooReportingCampaignFlats = 'yahooReportingCampaignFlats',
  AdformReportingCampaigns = 'adformReportingCampaigns',
  AdformReportingCampaignFlats = 'adformReportingCampaignFlats',
  TaboolaReportingCampaigns = 'taboolaReportingCampaigns',
  TaboolaReportingCampaignFlats = 'taboolaReportingCampaignFlats',
  TradedeskReportingCampaigns = 'tradedeskReportingCampaigns',
  TradedeskReportingCampaignFlats = 'tradedeskReportingCampaignFlats',
}

interface GetBookingDataBookingTrackerResponse {
  bookingTracker: BookingTrackerData | null;
  activeJobs: ActiveJobData[];
  completeJobs: CompleteJobData[];
  recognizedBookings: RecognizedBookingData[];
  boostBookings: BoostBookingsData[];
  bookingEmails: BookingEmailData[];
  optimisations: OptimisationData[];
  facebookReportingCampaigns: FacebookReportingCampaignData[];
  facebookReportingCampaignFlats: FacebookReportingCampaignFlatData[];
  mediamathReportingCampaigns: MediamathReportingCampaignData[];
  mediamathReportingCampaignFlats: MediamathReportingCampaignFlatData[];
  yahooReportingCampaigns: YahooReportingCampaignData[];
  yahooReportingCampaignFlats: YahooReportingCampaignFlatData[];
  adformReportingCampaigns: AdformReportingCampaignData[];
  adformReportingCampaignFlats: AdformReportingCampaignFlatData[];
  taboolaReportingCampaigns: TaboolaReportingCampaignData[];
  taboolaReportingCampaignFlats: TaboolaReportingCampaignFlatData[];
  tradedeskReportingCampaigns: TradedeskReportingCampaignData[];
  tradedeskReportingCampaignFlats: TradedeskReportingCampaignFlatData[];
}

type GetBookingDataResponses<T> = {
  [K in keyof T]: { [P in K]: T[K] };
}[keyof T];

// Julian's idea for short syntax. Union of { bookingTracker: BookingTrackerData | null } | { activeJobs: ActiveJobData[] } | ...
export type GetBookingDataResponse =
  GetBookingDataResponses<GetBookingDataBookingTrackerResponse>;

const renderSection = (
  query: UseQueryResult<GetBookingDataResponse | undefined, Error>,
): JSX.Element | undefined | false => {
  if (!isPresent(query.data)) {
    return;
  }
  if (BookingDataSection.BookingTracker in query.data) {
    const data = query.data.bookingTracker;
    return (
      isPresent(data) && (
        <BookingDataBookingTrackerPanel
          data={data}
          key={BookingDataSection.BookingTracker}
        />
      )
    );
  }
  if (BookingDataSection.ActiveJobs in query.data) {
    const data = query.data.activeJobs;
    return (
      data.length !== 0 && (
        <BookingDataActiveJobsListPanel
          data={data}
          key={BookingDataSection.ActiveJobs}
        />
      )
    );
  }
  if (BookingDataSection.CompleteJobs in query.data) {
    const data = query.data.completeJobs;
    return (
      data.length !== 0 && (
        <BookingDataCompletedJobsListPanel
          data={data}
          key={BookingDataSection.CompleteJobs}
        />
      )
    );
  }
  if (BookingDataSection.RecognizedBookings in query.data) {
    const data = query.data.recognizedBookings;
    return (
      data.length !== 0 && (
        <BookingDataRecognisedBookingsListPanel
          data={data}
          key={BookingDataSection.RecognizedBookings}
        />
      )
    );
  }
  if (BookingDataSection.BoostBookings in query.data) {
    const data = query.data.boostBookings;
    return (
      data.length !== 0 && (
        <BookingDataBoostBookingsListPanel
          data={data}
          key={BookingDataSection.BoostBookings}
        />
      )
    );
  }
  if (BookingDataSection.BookingEmails in query.data) {
    const data = query.data.bookingEmails;
    return (
      data.length !== 0 && (
        <BookingDataGoLiveEmailsPanel
          data={data}
          key={BookingDataSection.BookingEmails}
        />
      )
    );
  }
  if (BookingDataSection.Optimisations in query.data) {
    const data = query.data.optimisations;
    return (
      data.length !== 0 && (
        <BookingDataOptimisationsListPanel
          data={data}
          key={BookingDataSection.Optimisations}
        />
      )
    );
  }
  if (BookingDataSection.FacebookReportingCampaigns in query.data) {
    const data = query.data.facebookReportingCampaigns;
    return (
      data.length !== 0 && (
        <BookingDataFbDailyCampaignReportingPanel
          data={data}
          key={BookingDataSection.FacebookReportingCampaigns}
        />
      )
    );
  }
  if (BookingDataSection.FacebookReportingCampaignFlats in query.data) {
    const data = query.data.facebookReportingCampaignFlats;
    return (
      data.length !== 0 && (
        <BookingDataFbTotalCampaignReportingPanel
          data={data}
          key={BookingDataSection.FacebookReportingCampaignFlats}
        />
      )
    );
  }
  if (BookingDataSection.MediamathReportingCampaigns in query.data) {
    const data = query.data.mediamathReportingCampaigns;
    return (
      data.length !== 0 && (
        <BookingDataMmDailyCampaignReportingPanel
          data={data}
          key={BookingDataSection.MediamathReportingCampaigns}
        />
      )
    );
  }
  if (BookingDataSection.MediamathReportingCampaignFlats in query.data) {
    const data = query.data.mediamathReportingCampaignFlats;
    return (
      data.length !== 0 && (
        <BookingDataMmTotalCampaignReportingPanel
          data={data}
          key={BookingDataSection.MediamathReportingCampaignFlats}
        />
      )
    );
  }
  if (BookingDataSection.YahooReportingCampaigns in query.data) {
    const data = query.data.yahooReportingCampaigns;
    return (
      data.length !== 0 && (
        <BookingDataYhDailyCampaignReportingPanel
          data={data}
          key={BookingDataSection.YahooReportingCampaigns}
        />
      )
    );
  }
  if (BookingDataSection.YahooReportingCampaignFlats in query.data) {
    const data = query.data.yahooReportingCampaignFlats;
    return (
      data.length !== 0 && (
        <BookingDataYhTotalCampaignReportingPanel
          data={data}
          key={BookingDataSection.YahooReportingCampaignFlats}
        />
      )
    );
  }
  if (BookingDataSection.AdformReportingCampaigns in query.data) {
    const data = query.data.adformReportingCampaigns;
    return (
      data.length !== 0 && (
        <BookingDataAfDailyCampaignReportingPanel
          data={data}
          key={BookingDataSection.AdformReportingCampaigns}
        />
      )
    );
  }
  if (BookingDataSection.AdformReportingCampaignFlats in query.data) {
    const data = query.data.adformReportingCampaignFlats;
    return (
      data.length !== 0 && (
        <BookingDataAfTotalCampaignReportingPanel
          data={data}
          key={BookingDataSection.AdformReportingCampaignFlats}
        />
      )
    );
  }
  if (BookingDataSection.TaboolaReportingCampaigns in query.data) {
    const data = query.data.taboolaReportingCampaigns;
    return (
      data.length !== 0 && (
        <BookingDataTbDailyCampaignReportingPanel
          data={data}
          key={BookingDataSection.TaboolaReportingCampaigns}
        />
      )
    );
  }
  if (BookingDataSection.TaboolaReportingCampaignFlats in query.data) {
    const data = query.data.taboolaReportingCampaignFlats;
    return (
      data.length !== 0 && (
        <BookingDataTbTotalCampaignReportingPanel
          data={data}
          key={BookingDataSection.TaboolaReportingCampaignFlats}
        />
      )
    );
  }
  if (BookingDataSection.TradedeskReportingCampaigns in query.data) {
    const data = query.data.tradedeskReportingCampaigns;
    return (
      data.length !== 0 && (
        <BookingDataTtdDailyCampaignReportingPanel
          data={data}
          key={BookingDataSection.TradedeskReportingCampaigns}
        />
      )
    );
  }
  if (BookingDataSection.TradedeskReportingCampaignFlats in query.data) {
    const data = query.data.tradedeskReportingCampaignFlats;
    return (
      data.length !== 0 && (
        <BookingDataTtdTotalCampaignReportingPanel
          data={data}
          key={BookingDataSection.TradedeskReportingCampaignFlats}
        />
      )
    );
  }
};

const BookingDataDetails = ({
  bookingId,
}: BookingDataDetailsProps): JSX.Element => {
  const queries = useQueries({
    queries: Object.values(BookingDataSection).map((section) => ({
      queryKey: ['bookingDataByBookingId', bookingId, section],
      queryFn: () =>
        fetchJson<GetBookingDataBookingTrackerResponse>(
          `/booking-data/${bookingId}/${section}`,
        ),
    })),
  });

  return (
    <Stack gap="extraLarge">
      {queries.some((query) => query.isLoading) && <PageLoadingSpinner />}
      {queries.map(
        (query, index) =>
          query.isError && (
            <ErrorAlert error={query.error} key={`key-${index}`} />
          ),
      )}
      {queries.map((query) => renderSection(query))}
    </Stack>
  );
};

export default BookingDataDetails;
