import {
  Tab,
  TabList,
  TabBar,
  Tabs,
  spacingHelper,
  Stack,
} from '@construct-kit/core';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const LogoContainer = styled.div`
  padding: ${spacingHelper('medium 0 medium large')};
  position: absolute;
  left: 0;
  top: 0;
`;

const Nav = styled.nav`
  padding-top: ${spacingHelper('medium')};
`;

const Logo = styled.img`
  width: 9.4rem;
  height: 2.25rem;
`;

const StyledTabBar = styled(TabBar)`
  margin-bottom: 0;
`;

const tabs = [
  { key: 0, title: 'Home', to: '/' },
  { key: 1, title: 'Packages', to: '/packages' },
  {
    key: 2,
    title: 'Targeting Mods',
    to: '/targeting-mods',
  },
  { key: 3, title: 'FB Ad Count', to: '/fb-ad-count' },
  {
    key: 4,
    title: 'FB Audience Count',
    to: '/fb-audience-count',
  },
  {
    key: 5,
    title: 'Status Monitor',
    to: '/status-monitor',
  },
  { key: 6, title: 'Tracker', to: '/tracker' },
  { key: 7, title: 'Boost Tracker', to: '/boost-tracker' },
  {
    key: 8,
    title: 'Booking Data',
    to: '/booking-data',
  },
  {
    key: 9,
    title: 'Job History',
    to: '/job-history',
  },
];

const getSelectedTabIndex = (pathname: string): number | undefined => {
  if (pathname === '/') {
    return 0;
  }

  const selectedTabIndex = tabs.findIndex(
    ({ to }) => to !== '/' && pathname.indexOf(to) === 0,
  );

  return selectedTabIndex !== -1 ? selectedTabIndex : undefined;
};

const Header = (): JSX.Element => {
  const location = useLocation();
  const selectedTab = getSelectedTabIndex(location.pathname);

  return (
    <Stack gap="0">
      <LogoContainer>
        <Logo src="/logo.png" alt="logo" />
      </LogoContainer>
      <Nav>
        <Tabs
          defaultSelectedTab={0}
          selectedTab={selectedTab}
          variant="align-right"
        >
          <StyledTabBar>
            <TabList buttonColor="backgroundPrimaryBase">
              {tabs.map(({ key, title, to }) => (
                <Tab key={key} as={NavLink} to={to}>
                  {title}
                </Tab>
              ))}
            </TabList>
          </StyledTabBar>
        </Tabs>
      </Nav>
    </Stack>
  );
};

export default Header;
