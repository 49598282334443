import styled from 'styled-components';
import { paletteHelper, spacingHelper } from '@construct-kit/core';

export const DatePickerStyled = styled.div`
  .DateInput {
    width: 16rem;

    &_input {
      text-align: center;
      font-size: 1rem;
      padding: ${spacingHelper('0.375rem 0 0.375rem')};

      &__focused {
        border-bottom: 2px solid ${paletteHelper('borderFocused')};
      }
    }
  }

  .CalendarDay {
    vertical-align: middle;

    &__default {
      color: ${paletteHelper('textDisabled')};
      border: none;

      &:active,
      &:focus,
      &:hover {
        color: ${paletteHelper('textDisabled')};
        background: ${paletteHelper('backgroundHoveredPrimary')};
        border: none;
      }
    }

    &__today {
      color: ${paletteHelper('textPrimary')};
      font-weight: bolder;

      &:hover {
        color: ${paletteHelper('textPrimary')};
      }
    }

    &__selected {
      &,
      &:active,
      &:focus,
      &:hover {
        color: ${paletteHelper('textInverted')};
        background: ${paletteHelper('backgroundSelected')};
      }

      &_span {
        &,
        &:active,
        &:focus,
        &:hover {
          color: ${paletteHelper('textPrimary')};
          background: ${paletteHelper('backgroundHoveredPrimary')};
        }
      }
    }

    &__hovered {
      &_span {
        &,
        &:hover {
          color: ${paletteHelper('textPrimary')};
          background: ${paletteHelper('backgroundHoveredPrimary')};
        }
      }
    }
  }

  // Fixes Error: NaN is an invalid value for the height css style property.
  // https://github.com/react-dates/react-dates/issues/1426#issuecomment-708058031
  .CalendarMonth_caption {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
