import { Link } from '@construct-kit/core';
import { ValidationWarningBooking } from '../../pages/UploadBookingCampaign';
import BookingsModal from './BookingsModal';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import formatPrice from '../../utils/formatPrice';

interface RuralOver3MillionWarningModalProps {
  bookings: ValidationWarningBooking[];
}

const RuralOver3MillionWarningModal = ({
  bookings,
}: RuralOver3MillionWarningModalProps): JSX.Element => {
  return (
    <BookingsModal heading="Rural and over $3m">
      <Table>
        <TableHeader>
          <TableHeaderColumn>Booking ID</TableHeaderColumn>
          <TableHeaderColumn>Listing ID</TableHeaderColumn>
          <TableHeaderColumn>Property Type</TableHeaderColumn>
          <TableHeaderColumn>Listing Price</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {bookings.length === 0 ? (
            <EmptyTableBody />
          ) : (
            bookings.map(
              ({
                rowNumber,
                bookingId,
                listingId,
                propertyType,
                listingPrice,
              }) => (
                <TableRow key={rowNumber}>
                  <TableContentColumn>{bookingId}</TableContentColumn>
                  <TableContentColumn>
                    <Link
                      href={`https://www.realestate.com.au/${listingId}`}
                      target="_blank"
                    >
                      {listingId}
                    </Link>
                  </TableContentColumn>
                  <TableContentColumn>{propertyType}</TableContentColumn>
                  <TableContentColumn>
                    {formatPrice(listingPrice)}
                  </TableContentColumn>
                </TableRow>
              ),
            )
          )}
        </TableBody>
      </Table>
    </BookingsModal>
  );
};

export default RuralOver3MillionWarningModal;
