import { Modal, Dialog } from '@construct-kit/core';
import { isPresent } from '../../utils/helpers';
import { match, P as Pa } from 'ts-pattern';
import StartDateErrorModal from './StartDateErrorModal';
import MatchingPackageErrorModal from './MatchingPackageErrorModal';
import MatchingPriceTargetErrorModal from './MatchingPriceTargetErrorModal';
import MatchingPropertyTypeTargetErrorModal from './MatchingPropertyTypeTargetErrorModal';
import MatchingPostcodeRadiusTargetErrorModal from './MatchingPostcodeRadiusTargetErrorModal';
import TestAgencyErrorModal from './TestAgencyErrorModal';
import RuralOver3MillionWarningModal from './RuralOver3MillionWarningModal';
import DreamHomeWarningModal from './DreamHomeWarningModal';
import PlatinumWarningModal from './PlatinumWarningModal';
import DuplicateListingIdCompletedWarningModal from './DuplicateListingIdCompletedWarningModal';
import DuplicateListingIdInFileWarningModal from './DuplicateListingIdInFileWarningModal';
import DuplicateBookingIdCompletedWarningModal from './DuplicateBookingIdCompletedWarningModal';
import DuplicateBookingIdInFileWarningModal from './DuplicateBookingIdInFileWarningModal';
import StateACTWarningModal from './StateACTWarningModal';
import { ValidationErrorOrWarning } from '../ProcessBookingCampaignPanel';
import { getAppRoot } from '../../utils/getAppRoot';
import ListingNotFoundWarningModal from './ListingNotFoundWarningModal';
import CustomTargetingErrorModal from './CustomTargetingErrorModal';
import PackageIdsWarningModal from './PackageIdsWarningModal';

interface ErrorsOrWarningsViewListingModelProps {
  modalValidation: ValidationErrorOrWarning | undefined;
  onClose: (modalValidation: ValidationErrorOrWarning | undefined) => void;
}

const ErrorsOrWarningsViewListingModel = ({
  modalValidation,
  onClose,
}: ErrorsOrWarningsViewListingModelProps): JSX.Element => {
  return (
    <Modal
      opened={isPresent(modalValidation)}
      onRequestClose={() => onClose(undefined)}
      getAppElement={getAppRoot}
    >
      <Dialog onClose={() => onClose(undefined)} maxWidth="100rem">
        {match(modalValidation)
          .with({ validationType: 'startDate' }, ({ bookings }) => (
            <StartDateErrorModal bookings={bookings} />
          ))
          .with({ validationType: 'matchingPackage' }, ({ bookings }) => (
            <MatchingPackageErrorModal bookings={bookings} />
          ))
          .with({ validationType: 'matchingPriceTarget' }, ({ bookings }) => (
            <MatchingPriceTargetErrorModal bookings={bookings} />
          ))
          .with(
            { validationType: 'matchingPropertyTypeTarget' },
            ({ bookings }) => (
              <MatchingPropertyTypeTargetErrorModal bookings={bookings} />
            ),
          )
          .with(
            { validationType: 'matchingPostcodeRadiusTarget' },
            ({ bookings }) => (
              <MatchingPostcodeRadiusTargetErrorModal bookings={bookings} />
            ),
          )
          .with({ validationType: 'testAgency' }, ({ bookings }) => (
            <TestAgencyErrorModal bookings={bookings} />
          ))
          .with({ validationType: 'customTargeting' }, ({ bookings }) => (
            <CustomTargetingErrorModal bookings={bookings} />
          ))
          .with({ validationType: 'ruralOver3Million' }, ({ bookings }) => (
            <RuralOver3MillionWarningModal bookings={bookings} />
          ))
          .with({ validationType: 'dreamHome' }, ({ bookings }) => (
            <DreamHomeWarningModal bookings={bookings} />
          ))
          .with({ validationType: 'platinum' }, ({ bookings }) => (
            <PlatinumWarningModal bookings={bookings} />
          ))
          .with({ validationType: 'packageIds' }, ({ bookings }) => (
            <PackageIdsWarningModal bookings={bookings} />
          ))
          .with(
            { validationType: 'duplicateListingIdCompleted' },
            ({ bookings }) => (
              <DuplicateListingIdCompletedWarningModal bookings={bookings} />
            ),
          )
          .with(
            { validationType: 'duplicateListingIdInFile' },
            ({ bookings }) => (
              <DuplicateListingIdInFileWarningModal bookings={bookings} />
            ),
          )
          .with(
            { validationType: 'duplicateBookingIdCompleted' },
            ({ bookings }) => (
              <DuplicateBookingIdCompletedWarningModal bookings={bookings} />
            ),
          )
          .with(
            { validationType: 'duplicateBookingIdInFile' },
            ({ bookings }) => (
              <DuplicateBookingIdInFileWarningModal bookings={bookings} />
            ),
          )
          .with({ validationType: 'stateACT' }, ({ bookings }) => (
            <StateACTWarningModal bookings={bookings} />
          ))
          .with({ validationType: 'listingNotFound' }, ({ bookings }) => (
            <ListingNotFoundWarningModal bookings={bookings} />
          ))
          .with(Pa.nullish, () => null)
          .exhaustive()}
      </Dialog>
    </Modal>
  );
};

export default ErrorsOrWarningsViewListingModel;
