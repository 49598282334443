import { Spinner, spacingHelper } from '@construct-kit/core';
import styled from 'styled-components';

const PageLoadingSpinner = styled(Spinner)`
  position: absolute;
  top: calc(50% - ${spacingHelper('extraLarge')});
  left: calc(50% - ${spacingHelper('extraLarge')});
`;

const ButtonLoadingSpinner = styled(Spinner)`
  width: ${spacingHelper('large')};
  height: ${spacingHelper('large')};
`;

export { PageLoadingSpinner, ButtonLoadingSpinner };
