import Panel from './Panel';
import {
  Stack,
  Link,
  H4,
  spacingHelper,
  ArrowRightMd,
  Button,
} from '@construct-kit/core';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

const ChildrenStack = styled(Stack)`
  margin-top: ${spacingHelper('-extraSmall')};
`;

interface BookingCampaignPanelProps {
  isUploadDisabled: boolean;
}

const BookingCampaignPanel = ({
  isUploadDisabled,
}: BookingCampaignPanelProps): JSX.Element => (
  <Panel heading="Bookings Upload" showDivider={false}>
    <ChildrenStack gap="medium">
      <H4 variant="body02">Upload bookings file</H4>
      {(!isUploadDisabled && (
        <Link
          as={RouterLink}
          to="/upload-booking-campaign"
          variant="button-outline"
          sizeVariant="medium"
          fullWidth={true}
          icon={<ArrowRightMd />}
          iconPlacement="right"
        >
          Upload bookings
        </Link>
      )) || (
        <Button
          variant="primary"
          disabled={true}
          sizeVariant="medium"
          fullWidth={true}
          icon={<ArrowRightMd />}
          iconPlacement="right"
        >
          Upload bookings
        </Button>
      )}
    </ChildrenStack>
  </Panel>
);

export default BookingCampaignPanel;
