import { useQuery } from '@tanstack/react-query';
import {
  Link,
  Select,
  Stack,
  Skeleton,
  SelectOption,
  spacingHelper,
} from '@construct-kit/core';
import { PropertyTypeWarningBooking } from '../../pages/UploadBookingCampaign';
import {
  EmptyTableBody,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import { fetchJson } from '../../API/fetch';
import ErrorAlert from '../ErrorAlert';
import styled from 'styled-components';
import { UpdateBookingPropertyType } from '../ProcessBookingCampaignPanel';
import { isPresent } from '../../utils/helpers';

const StyledTableHeaderColumn = styled(TableHeaderColumn)`
  width: 14.2rem;
`;

const TableHeaderColumnWithSelect = styled(TableHeaderColumn)`
  width: 15.6rem;
`;

const StyledTableContentColumn = styled(TableContentColumn)`
  padding: ${spacingHelper('extraSmall medium')};
`;

interface PropertyTypesWarningPanelProps {
  bookings: PropertyTypeWarningBooking[];
  onPropertyTypeChange: (newBooking: UpdateBookingPropertyType) => void;
}

interface PropertyType {
  id: number;
  propertyType: string;
}

interface GetPropertyTypeResponse {
  results: PropertyType[];
}

const getPropertyTypesList = async (): Promise<PropertyType[]> => {
  const { results } =
    await fetchJson<GetPropertyTypeResponse>('/property-types');
  return results;
};

const PropertyTypesWarningPanel = ({
  bookings,
  onPropertyTypeChange,
}: PropertyTypesWarningPanelProps): JSX.Element => {
  const {
    isLoading,
    isError,
    error,
    data: propertyTypes,
  } = useQuery({
    queryKey: ['propertyTypesList'],
    queryFn: getPropertyTypesList,
  });

  const propertyTypeOptions = propertyTypes?.map(({ propertyType }) => ({
    label: propertyType,
    value: propertyType,
  }));

  const getDefaultPropertyType = (
    propertyTypeOptions: SelectOption[],
    propertyType: string,
  ): SelectOption | undefined => {
    return propertyTypeOptions.find((b) => b.value === propertyType);
  };

  const selectChangeHandler =
    (bookingId: string) => (selectedOption?: SelectOption | null) => {
      if (isPresent(selectedOption)) {
        onPropertyTypeChange({
          bookingId,
          propertyType: selectedOption.value,
        });
      }
    };

  return (
    <form>
      <Stack gap="extraSmall" inset="large 0 0">
        {isError ? (
          <ErrorAlert error={error} />
        ) : (
          <Stack inset="extraSmall 0 0">
            <table>
              <TableHeader>
                <TableHeaderColumn>Booking ID</TableHeaderColumn>
                <StyledTableHeaderColumn>Listing ID</StyledTableHeaderColumn>
                <StyledTableHeaderColumn>
                  Current property type
                </StyledTableHeaderColumn>
                <TableHeaderColumnWithSelect>
                  Edit property type
                </TableHeaderColumnWithSelect>
              </TableHeader>
              <TableBody>
                {bookings.length === 0 ? (
                  <EmptyTableBody />
                ) : (
                  bookings.map(({ bookingId, listingId, propertyType }) => {
                    return (
                      <TableRow key={listingId}>
                        <TableContentColumn>{bookingId}</TableContentColumn>
                        <TableContentColumn>
                          <Link
                            href={`https://www.realestate.com.au/${listingId}`}
                            target="_blank"
                          >
                            {listingId}
                          </Link>
                        </TableContentColumn>
                        <TableContentColumn>{propertyType}</TableContentColumn>
                        <StyledTableContentColumn>
                          {isLoading && (
                            <Skeleton>
                              <Select
                                label=""
                                options={[]}
                                sizeVariant="small"
                                hideLabel={true}
                              />
                            </Skeleton>
                          )}
                          {propertyTypeOptions && (
                            <Select
                              label="Property type"
                              options={propertyTypeOptions}
                              sizeVariant="small"
                              hideLabel={true}
                              defaultSelectedOption={getDefaultPropertyType(
                                propertyTypeOptions,
                                propertyType,
                              )}
                              onSelectedOptionChange={selectChangeHandler(
                                bookingId,
                              )}
                            />
                          )}
                        </StyledTableContentColumn>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </table>
          </Stack>
        )}
      </Stack>
    </form>
  );
};

export default PropertyTypesWarningPanel;
