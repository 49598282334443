import { useQuery } from '@tanstack/react-query';
import {
  Link,
  Select,
  Stack,
  Skeleton,
  SelectOption,
  spacingHelper,
} from '@construct-kit/core';
import {
  PropertyRegionWarningBooking,
  Region,
} from '../../pages/UploadBookingCampaign';
import {
  EmptyTableBody,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import { fetchJson } from '../../API/fetch';
import ErrorAlert from '../ErrorAlert';
import styled from 'styled-components';
import { UpdateBookingRegion } from '../ProcessBookingCampaignPanel';
import { isPresent } from '../../utils/helpers';

const StyledTableHeaderColumn = styled(TableHeaderColumn)`
  width: 14.2rem;
`;

const TableHeaderColumnWithSelect = styled(TableHeaderColumn)`
  width: 15.6rem;
`;

const StyledTableContentColumn = styled(TableContentColumn)`
  padding: ${spacingHelper('extraSmall medium')};
`;

export interface RegionsResponse {
  regions: Region[];
}

const getRegions = (): Promise<RegionsResponse> =>
  fetchJson<RegionsResponse>('/regions');

const regionToOptionLabel = (region: Region): string =>
  `${region.state}_${region.region}`.toLowerCase();

const regionToSelectOption = (region: Region): SelectOption => ({
  value: region.id.toString(),
  label: regionToOptionLabel(region),
});

const getSelectedOption = (
  regionOptions: SelectOption[] | undefined,
  suggestedRegion: Region | null | undefined,
): SelectOption | undefined => {
  if (!isPresent(regionOptions)) {
    return undefined;
  }

  if (!isPresent(suggestedRegion)) {
    return undefined;
  }

  return regionOptions.find(
    ({ value }) => value === suggestedRegion.id.toString(),
  );
};

interface PropertyRegionsWarningPanelProps {
  bookings: PropertyRegionWarningBooking[];
  onRegionChange: (newBooking: UpdateBookingRegion) => void;
}

const PropertyRegionsWarningPanel = ({
  bookings,
  onRegionChange,
}: PropertyRegionsWarningPanelProps): JSX.Element => {
  const {
    isLoading,
    isError,
    error,
    data: regionsData,
  } = useQuery({
    queryKey: ['regions'],
    queryFn: getRegions,
  });

  const { regions } = regionsData ?? {};
  const regionOptions = regions?.map(regionToSelectOption);

  return (
    <form>
      <Stack gap="extraSmall" inset="large 0 0">
        {isError ? (
          <ErrorAlert error={error} />
        ) : (
          <Stack inset="extraSmall 0 0">
            <table>
              <TableHeader>
                <TableHeaderColumn>Booking ID</TableHeaderColumn>
                <StyledTableHeaderColumn>Listing ID</StyledTableHeaderColumn>
                <StyledTableHeaderColumn>
                  Suggested property region
                </StyledTableHeaderColumn>
                <TableHeaderColumnWithSelect>
                  Edit property region
                </TableHeaderColumnWithSelect>
              </TableHeader>
              <TableBody>
                {bookings.length === 0 ? (
                  <EmptyTableBody />
                ) : (
                  bookings.map(({ bookingId, listingId, suggestedRegion }) => {
                    const defaultSelectedRegionOption = getSelectedOption(
                      regionOptions,
                      suggestedRegion,
                    );

                    return (
                      <TableRow key={bookingId}>
                        <TableContentColumn>{bookingId}</TableContentColumn>
                        <TableContentColumn>
                          <Link
                            href={`https://www.realestate.com.au/${listingId}`}
                            target="_blank"
                          >
                            {listingId}
                          </Link>
                        </TableContentColumn>
                        <TableContentColumn>
                          {isPresent(suggestedRegion) &&
                            regionToOptionLabel(suggestedRegion)}
                        </TableContentColumn>
                        <StyledTableContentColumn>
                          {isLoading && (
                            <Skeleton>
                              <Select
                                label=""
                                options={[]}
                                sizeVariant="small"
                                hideLabel={true}
                              />
                            </Skeleton>
                          )}
                          {regionOptions && (
                            <Select
                              label="Property type"
                              options={regionOptions}
                              sizeVariant="small"
                              hideLabel={true}
                              defaultSelectedOption={
                                defaultSelectedRegionOption
                              }
                              onSelectedOptionChange={(selectedOption) => {
                                if (
                                  !isPresent(selectedOption) ||
                                  !isPresent(regions)
                                ) {
                                  return;
                                }

                                const selectRegion = regions.find(
                                  (region) =>
                                    region.id.toString() ===
                                    selectedOption.value,
                                ) as Region;

                                onRegionChange({
                                  bookingId,
                                  region: selectRegion,
                                });
                              }}
                            />
                          )}
                        </StyledTableContentColumn>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </table>
          </Stack>
        )}
      </Stack>
    </form>
  );
};

export default PropertyRegionsWarningPanel;
