import { useQuery } from '@tanstack/react-query';
import { fetchJson } from '../../API/fetch';
import { isPresent } from '../../utils/helpers';
import { getAppRoot } from '../../utils/getAppRoot';
import { Dialog, Modal } from '@construct-kit/core';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import ErrorAlert from '../ErrorAlert';
import { formatDateTime } from '../../utils/formatDate';
import Status from '../Status';
import styled from 'styled-components';
import { PageLoadingSpinner } from '../LoadingSpinner';

const Container = styled.div`
  min-width: 75rem;
  min-height: 30rem;
  padding: 1rem;
`;

export interface BookingTrackerHistoryModalProps {
  bookingId?: string;
  opened: boolean;
  onRequestClose: () => void;
}

interface BookingLog {
  id: string;
  action: string;
  system: string;
  status: string;
  note: string | null;
  updatedAt: string | null;
}

interface HistoryDataResponse {
  history: BookingLog[];
}

const getBookingTrackerHistoryData = async (
  bookingId: string,
): Promise<BookingLog[]> => {
  const { history } = await fetchJson<HistoryDataResponse>(
    `/booking-trackers/${bookingId}/history`,
  );
  return history;
};

const BookingTrackerHistoryModal = ({
  bookingId,
  opened,
  onRequestClose,
}: BookingTrackerHistoryModalProps): JSX.Element => {
  const {
    isLoading,
    isError,
    error,
    data: bookingTrackerHistoryData,
  } = useQuery({
    queryKey: ['bookingTrackerHistoryData', bookingId],
    queryFn: () => {
      if (isPresent(bookingId)) {
        return getBookingTrackerHistoryData(bookingId);
      }
    },
    enabled: isPresent(bookingId),
  });

  return (
    <Modal
      opened={opened}
      onRequestClose={onRequestClose}
      getAppElement={getAppRoot}
    >
      <Dialog onClose={onRequestClose} maxWidth="80rem">
        <Container>
          {isLoading && <PageLoadingSpinner />}
          {isError && <ErrorAlert error={error} />}
          {bookingTrackerHistoryData && (
            <Table>
              <TableHeader>
                <TableHeaderColumn>Action</TableHeaderColumn>
                <TableHeaderColumn>System</TableHeaderColumn>
                <TableHeaderColumn>Status</TableHeaderColumn>
                <TableHeaderColumn>Updated</TableHeaderColumn>
                <TableHeaderColumn>Note</TableHeaderColumn>
              </TableHeader>
              <TableBody>
                {bookingTrackerHistoryData.length === 0 ? (
                  <EmptyTableBody />
                ) : (
                  bookingTrackerHistoryData.map(
                    ({ id, action, system, status, note, updatedAt }) => (
                      <TableRow key={id}>
                        <TableContentColumn>{action}</TableContentColumn>
                        <TableContentColumn>{system}</TableContentColumn>
                        <TableContentColumn>
                          <Status status={status} />
                        </TableContentColumn>
                        <TableContentColumn>
                          {formatDateTime(updatedAt)}
                        </TableContentColumn>
                        <TableContentColumn noWrap={false}>
                          {note}
                        </TableContentColumn>
                      </TableRow>
                    ),
                  )
                )}
              </TableBody>
            </Table>
          )}
        </Container>
      </Dialog>
    </Modal>
  );
};

export default BookingTrackerHistoryModal;
