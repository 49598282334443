import { ReactNode } from 'react';
import { Stack, H2 } from '@construct-kit/core';

type ValidationErrorHeading =
  | 'Past Start Date'
  | 'Unsuccessful Package Match'
  | 'Unsuccessful Price Target Match'
  | 'Unsuccessful Property Type Target Match'
  | 'Unsuccessful Postcode Radius Target Match'
  | 'Test Agency'
  | 'Invalid Custom Targeting';

type ValidationWarningHeading =
  | 'Duplicate Listing ID in Completed Bookings'
  | 'Duplicate Listing ID in Uploaded File'
  | 'Duplicate Booking ID in Completed Bookings'
  | 'Duplicate Booking ID in Uploaded File'
  | 'Rural and over $3m'
  | 'Dream Homes'
  | 'Platinum'
  | 'Package Ids'
  | 'ACT Properties'
  | 'Listing not found';

type ValidationHeading = ValidationErrorHeading | ValidationWarningHeading;

interface BookingsModalProps {
  heading: ValidationHeading;
  children: ReactNode;
}

const BookingsModal = ({
  heading,
  children,
}: BookingsModalProps): JSX.Element => {
  return (
    <Stack inset="extraSmall medium medium" gap="medium">
      <H2>{heading}</H2>
      {children}
    </Stack>
  );
};

export default BookingsModal;
