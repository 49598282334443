import { Dialog, Modal } from '@construct-kit/core';
import { useQuery } from '@tanstack/react-query';
import { ChartData } from 'chart.js';
import styled from 'styled-components';
import { fetchJson } from '../../API/fetch';
import { getAppRoot } from '../../utils/getAppRoot';
import Chart, { Scales } from '../Chart';
import ErrorAlert from '../ErrorAlert';
import { isPresent } from '../../utils/helpers';
import { PageLoadingSpinner } from '../LoadingSpinner';

interface ReportPlatformData {
  impressions: number[];
  clicks: number[];
}

interface ReportData {
  labels: string[];
  facebook: ReportPlatformData;
  adform: ReportPlatformData;
  yahoo: ReportPlatformData;
  taboola: ReportPlatformData;
}

export interface BookingTrackerReportModalProps {
  bookingId?: string;
  opened: boolean;
  onRequestClose: () => void;
}

const Container = styled.div`
  min-width: 60rem;
  min-height: 25rem;
  padding: 1rem;
`;

const ticksCallback = (x: string | number): string =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const scales: Scales = {
  'y-axis-1': {
    type: 'linear',
    position: 'left',
    display: true,
    beginAtZero: true,
    ticks: {
      callback: ticksCallback,
    },
  },
  'y-axis-2': {
    type: 'linear',
    position: 'right',
    display: true,
    beginAtZero: true,
    grid: {
      drawOnChartArea: false,
    },
    ticks: {
      callback: ticksCallback,
    },
  },
};

const getBookingTrackerReportModalData = async (
  bookingId: string,
): Promise<ReportData> => {
  return fetchJson<ReportData>(`/booking-trackers/${bookingId}/report`);
};

const generateChartData = (
  originalData: ReportData | undefined,
): ChartData<'line', unknown, unknown> | undefined => {
  if (originalData) {
    const { labels, facebook, adform, yahoo, taboola } = originalData;
    const chartData = {
      labels,
      datasets: [
        {
          label: 'Facebook Impressions',
          data: facebook.impressions,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgb(255, 99, 132)',
          yAxisID: 'y-axis-1',
        },
        {
          label: 'Facebook Clicks',
          data: facebook.clicks,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgb(255, 99, 132)',
          borderDash: [10, 5],
          yAxisID: 'y-axis-2',
        },
        {
          label: 'Adform Impressions',
          data: adform.impressions,
          borderColor: 'rgb(255, 165, 0)',
          backgroundColor: 'rgb(255, 165, 0)',
          yAxisID: 'y-axis-1',
        },
        {
          label: 'Adform Clicks',
          data: adform.clicks,
          borderColor: 'rgb(255, 165, 0',
          backgroundColor: 'rgb(255, 165, 0)',
          borderDash: [10, 5],
          yAxisID: 'y-axis-2',
        },
        {
          label: 'Yahoo Impressions',
          data: yahoo.impressions,
          borderColor: 'rgb(44, 156, 40)',
          backgroundColor: 'rgb(44, 156, 40)',
          yAxisID: 'y-axis-1',
        },
        {
          label: 'Yahoo Clicks',
          data: yahoo.clicks,
          borderColor: 'rgb(44, 156, 40)',
          backgroundColor: 'rgb(44, 156, 40)',
          borderDash: [10, 5],
          yAxisID: 'y-axis-2',
        },
        {
          label: 'Taboola Impressions',
          data: taboola.impressions,
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgb(54, 162, 235)',
          yAxisID: 'y-axis-1',
        },
        {
          label: 'Taboola Clicks',
          data: taboola.clicks,
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgb(54, 162, 235)',
          borderDash: [10, 5],
          yAxisID: 'y-axis-2',
        },
      ],
    };
    return chartData;
  }
};

const BookingTrackerReportModal = ({
  bookingId,
  opened,
  onRequestClose,
}: BookingTrackerReportModalProps): JSX.Element => {
  const {
    isLoading,
    isError,
    error,
    data: bookingTrackerReportModalData,
  } = useQuery({
    queryKey: ['bookingTrackerReportModalData', bookingId],
    queryFn: () => {
      if (isPresent(bookingId)) {
        return getBookingTrackerReportModalData(bookingId);
      }
    },
    enabled: isPresent(bookingId),
  });

  const chartData = generateChartData(bookingTrackerReportModalData);

  return (
    <Modal
      opened={opened}
      onRequestClose={onRequestClose}
      getAppElement={getAppRoot}
    >
      <Dialog onClose={onRequestClose} maxWidth="65rem">
        <Container>
          {isLoading && <PageLoadingSpinner />}
          {isError && <ErrorAlert error={error} />}
          {chartData && (
            <Chart
              label="booking report chart"
              width="60rem"
              height="25rem"
              legendPosition="right"
              data={chartData}
              scales={scales}
            />
          )}
        </Container>
      </Dialog>
    </Modal>
  );
};

export default BookingTrackerReportModal;
