import { Button, H1, Inline, Link, Stack } from '@construct-kit/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchJson } from '../../API/fetch';
import ErrorAlert from '../../components/ErrorAlert';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../../components/Table/Table';
import { Link as RouterLink } from 'react-router-dom';
import { PackageResponse, PackagesResponseData } from './types';
import { useRef, useState } from 'react';
import { isPresent } from '../../utils/helpers';
import ConfirmModal from '../../components/ConfirmModal';
import { PageLoadingSpinner } from '../../components/LoadingSpinner';

type Variables = {
  isArchived: boolean;
  packageId: number;
};

const archivePackage = ({
  isArchived,
  packageId,
}: Variables): Promise<PackageResponse> =>
  fetchJson<PackageResponse>(`/packages/${packageId}`, {
    method: 'PUT',
    body: JSON.stringify({ isArchived }),
  });

const formatFlag = (flag: boolean): string => (flag ? 'Yes' : 'No');

const Packages = (): JSX.Element => {
  const {
    isLoading,
    isError,
    error,
    data: packagesData,
    refetch,
  } = useQuery({
    queryKey: ['packagesData'],
    queryFn: () => fetchJson<PackagesResponseData>('/packages'),
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const archivePackageIdRef = useRef<number>();
  const archivePackageNameRef = useRef<string>();

  const packagesToRender = packagesData?.packages.filter(
    (item) => !item.isArchived,
  );

  const closeModalAndResetUpdateMutation = (): void => {
    setIsModalOpen(false);
    updateMutation.reset();
    archivePackageIdRef.current = undefined;
  };

  const updateMutation = useMutation({
    mutationFn: archivePackage,
    onSuccess: async () => {
      closeModalAndResetUpdateMutation();
      await refetch();
    },
  });

  const getAdformCampId = ({
    adformCampId,
    adform3pdCampId,
    adformCookielessCampId,
    adformCookieless3pdCampId,
    adformCookieCookielessCampId,
    adformCookieCookieless3pdCampId,
    adformCampTypeToUse,
  }: {
    adformCampId: number | null;
    adform3pdCampId: number | null;
    adformCookielessCampId: number | null;
    adformCookieless3pdCampId: number | null;
    adformCookieCookielessCampId: number | null;
    adformCookieCookieless3pdCampId: number | null;
    adformCampTypeToUse: number;
  }): string => {
    switch (adformCampTypeToUse) {
      case 0:
        return adformCampId?.toString() ?? 'N/A';
      case 1:
        return adform3pdCampId?.toString() ?? 'N/A';
      case 2:
        return adformCookielessCampId?.toString() ?? 'N/A';
      case 3:
        return adformCookieless3pdCampId?.toString() ?? 'N/A';
      case 4:
        return adformCookieCookielessCampId?.toString() ?? 'N/A';
      case 5:
        return adformCookieCookieless3pdCampId?.toString() ?? 'N/A';
      default:
        return 'N/A';
    }
  };

  const getTradedeskCampId = ({
    tradedeskCampId,
    tradedeskCookielessCampId,
    tradedeskCookieCookielessCampId,
    tradedeskCampTypeToUse,
  }: {
    tradedeskCampId: string | null;
    tradedeskCookielessCampId: string | null;
    tradedeskCookieCookielessCampId: string | null;
    tradedeskCampTypeToUse: number;
  }): string => {
    switch (tradedeskCampTypeToUse) {
      case 0:
        return tradedeskCampId?.toString() ?? 'N/A';
      case 1:
        return tradedeskCookielessCampId?.toString() ?? 'N/A';
      case 2:
        return tradedeskCookieCookielessCampId?.toString() ?? 'N/A';
      default:
        return 'N/A';
    }
  };

  return (
    <Stack gap="medium">
      <H1>Packages</H1>
      {isLoading && <PageLoadingSpinner />}
      {isError && <ErrorAlert error={error} />}
      {packagesToRender && (
        <Table>
          <TableHeader>
            <TableHeaderColumn>Name</TableHeaderColumn>
            <TableHeaderColumn>Package ID</TableHeaderColumn>
            <TableHeaderColumn>Brand</TableHeaderColumn>
            <TableHeaderColumn>Duration</TableHeaderColumn>
            <TableHeaderColumn>Impressions</TableHeaderColumn>
            <TableHeaderColumn>Inspection Boost?</TableHeaderColumn>
            <TableHeaderColumn>Sold Boost?</TableHeaderColumn>
            <TableHeaderColumn>FB?</TableHeaderColumn>
            <TableHeaderColumn>YH Display Templ</TableHeaderColumn>
            <TableHeaderColumn>YH Native Templ</TableHeaderColumn>
            <TableHeaderColumn>AF Templ</TableHeaderColumn>
            <TableHeaderColumn>TTD Templ</TableHeaderColumn>
            <TableHeaderColumn />
          </TableHeader>
          <TableBody>
            {packagesToRender.length === 0 ? (
              <EmptyTableBody />
            ) : (
              packagesToRender.map(
                ({
                  id,
                  packageId,
                  name,
                  brand,
                  duration,
                  imps,
                  boostFlag,
                  soldBoostFlag,
                  facebookFlag,
                  yahooDisplayFlag,
                  yahooDisplayCampId,
                  yahooNativeFlag,
                  yahooNativeCampId,
                  adformFlag,
                  adformCampId,
                  adform3pdCampId,
                  adformCookielessCampId,
                  adformCookieless3pdCampId,
                  adformCookieCookielessCampId,
                  adformCookieCookieless3pdCampId,
                  adformCampTypeToUse,
                  tradedeskFlag,
                  tradedeskCampId,
                  tradedeskCookielessCampId,
                  tradedeskCookieCookielessCampId,
                  tradedeskCampTypeToUse,
                }) => (
                  <TableRow key={id}>
                    <TableContentColumn>{name}</TableContentColumn>
                    <TableContentColumn>{packageId}</TableContentColumn>
                    <TableContentColumn>{brand}</TableContentColumn>
                    <TableContentColumn>{duration}</TableContentColumn>
                    <TableContentColumn>{imps}</TableContentColumn>
                    <TableContentColumn>
                      {formatFlag(boostFlag)}
                    </TableContentColumn>
                    <TableContentColumn>
                      {formatFlag(soldBoostFlag)}
                    </TableContentColumn>
                    <TableContentColumn>
                      {formatFlag(facebookFlag)}
                    </TableContentColumn>
                    <TableContentColumn>
                      {yahooDisplayFlag ? yahooDisplayCampId : 'N/A'}
                    </TableContentColumn>
                    <TableContentColumn>
                      {yahooNativeFlag ? yahooNativeCampId : 'N/A'}
                    </TableContentColumn>
                    <TableContentColumn>
                      {adformFlag
                        ? getAdformCampId({
                            adformCampId,
                            adform3pdCampId,
                            adformCookielessCampId,
                            adformCookieless3pdCampId,
                            adformCookieCookielessCampId,
                            adformCookieCookieless3pdCampId,
                            adformCampTypeToUse,
                          })
                        : 'N/A'}
                    </TableContentColumn>
                    <TableContentColumn>
                      {tradedeskFlag
                        ? getTradedeskCampId({
                            tradedeskCampId,
                            tradedeskCookielessCampId,
                            tradedeskCookieCookielessCampId,
                            tradedeskCampTypeToUse,
                          })
                        : 'N/A'}
                    </TableContentColumn>
                    <TableContentColumn>
                      <Inline
                        gap="small"
                        grow={false}
                        justifyContent={'center'}
                      >
                        <Link
                          as={RouterLink}
                          to={`/packages/edit/${id}`}
                          variant="primary"
                        >
                          Edit
                        </Link>
                        <Button
                          onClick={() => {
                            archivePackageIdRef.current = id;
                            archivePackageNameRef.current = name;
                            setIsModalOpen(true);
                          }}
                          variant="link-primary"
                        >
                          Archive
                        </Button>
                      </Inline>
                    </TableContentColumn>
                  </TableRow>
                ),
              )
            )}
          </TableBody>
        </Table>
      )}
      <Link variant="button-primary" as={RouterLink} to="/packages/create">
        Add new package
      </Link>
      <ConfirmModal
        headerText={`Are you sure you want to archive the ${archivePackageNameRef.current} package?`}
        okButtonText="Archive"
        isOpen={isModalOpen}
        isError={updateMutation.isError}
        error={updateMutation.error}
        isOkButtonDisabled={updateMutation.isPending}
        onClose={closeModalAndResetUpdateMutation}
        onCancel={closeModalAndResetUpdateMutation}
        onOk={() =>
          isPresent(archivePackageIdRef.current) &&
          updateMutation.mutate({
            isArchived: true,
            packageId: archivePackageIdRef.current,
          })
        }
      />
    </Stack>
  );
};

export default Packages;
