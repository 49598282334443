import { Dialog, Modal } from '@construct-kit/core';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { fetchJson } from '../../API/fetch';
import { getAppRoot } from '../../utils/getAppRoot';
import { isPresent } from '../../utils/helpers';
import ErrorAlert from '../ErrorAlert';
import FBAudienceDetailsTable, {
  FacebookAudience,
} from './FBAudienceDetailsTable';
import { PageLoadingSpinner } from '../LoadingSpinner';

const Container = styled.div`
  min-width: 75rem;
  min-height: 30rem;
  padding: 1rem;
`;

export interface FBAudienceDetailsModalProps {
  accountId?: string;
  opened: boolean;
  onRequestClose: () => void;
}

interface GetFacebookAudiencesApiResponse {
  facebookAudiencesApi: FacebookAudience[];
}

const FBAudienceApiDetailsModal = ({
  accountId = undefined,
  opened,
  onRequestClose,
}: FBAudienceDetailsModalProps): JSX.Element => {
  const {
    isLoading,
    isError,
    error,
    data: fbAudienceApiDetailsData,
  } = useQuery({
    queryKey: ['fbAudienceApiDetailsData', accountId],
    queryFn: () => {
      if (isPresent(accountId)) {
        return fetchJson<GetFacebookAudiencesApiResponse>(
          `/facebook-audiences-api?accountId=${accountId}`,
        );
      }
    },
    enabled: isPresent(accountId),
  });

  return (
    <Modal
      opened={opened}
      onRequestClose={onRequestClose}
      getAppElement={getAppRoot}
    >
      <Dialog onClose={onRequestClose} maxWidth="80rem">
        <Container>
          {isLoading && <PageLoadingSpinner />}
          {isError && <ErrorAlert error={error} />}
          {fbAudienceApiDetailsData && (
            <FBAudienceDetailsTable
              data={fbAudienceApiDetailsData.facebookAudiencesApi}
            />
          )}
        </Container>
      </Dialog>
    </Modal>
  );
};

export default FBAudienceApiDetailsModal;
