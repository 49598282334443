import styled from 'styled-components';
import { Divider, Inline, Link, Stack } from '@construct-kit/core';

export const FormInline = styled(Inline).attrs({
  gap: 'medium',
})`
  align-items: start;

  > * {
    width: 50%;
  }
`;

export const StyledDivider = styled(Divider)`
  margin-top: 0;
`;

export const StyledInline = styled(Inline).attrs({
  gap: 'medium',
})`
  width: 45%;
  margin-left: 55%;
`;

export const StyledStack = styled(Stack).attrs({
  gap: 'medium',
})``;

export const HidableLink = styled(Link)`
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
  display: block;
`;
