import { Button } from '@construct-kit/core';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import { FacebookAudienceCount } from './types';

export interface FBAudienceCountsTableProps {
  data: FacebookAudienceCount[];
  onAccountIdClick: (value: string) => void;
}

const FBAudienceCountsTable = ({
  data,
  onAccountIdClick,
}: FBAudienceCountsTableProps): JSX.Element => (
  <Table>
    <TableHeader>
      <TableHeaderColumn>Account ID</TableHeaderColumn>
      <TableHeaderColumn>Account Name</TableHeaderColumn>
      <TableHeaderColumn>Audience Count</TableHeaderColumn>
    </TableHeader>
    <TableBody>
      {data.length === 0 ? (
        <EmptyTableBody />
      ) : (
        data.map(({ accountId, accountName, audienceCount }) => (
          <TableRow key={accountId}>
            <TableContentColumn>
              <Button
                onClick={() => onAccountIdClick(accountId)}
                variant="link-primary"
              >
                {accountId}
              </Button>
            </TableContentColumn>
            <TableContentColumn>{accountName}</TableContentColumn>
            <TableContentColumn>{audienceCount}</TableContentColumn>
          </TableRow>
        ))
      )}
    </TableBody>
  </Table>
);

export default FBAudienceCountsTable;
