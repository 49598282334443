import { ReactNode } from 'react';
import { isPresent } from '../utils/helpers';
import { Link } from '@construct-kit/core';
import ErrorLinkMessage from './ErrorLinkMessage';

export interface JobError {
  message: string;
}

export type ImagesJobLink = 'image-preview' | JobError | null;

interface ImagesLinkProps {
  link: ImagesJobLink;
  listingId: number;
  children: ReactNode;
}

const ImagesLink = ({
  link,
  listingId,
  children,
}: ImagesLinkProps): JSX.Element | null => {
  if (!isPresent(link)) {
    return null;
  }

  if (link === 'image-preview') {
    return (
      <Link href={`/campaign-images-preview?listingId=${listingId}`}>
        {children}
      </Link>
    );
  }

  return <ErrorLinkMessage messageText={link.message} />;
};

export default ImagesLink;
