import { Link } from '@construct-kit/core';
import { ValidationErrorBooking } from '../../pages/UploadBookingCampaign';
import BookingsModal from './BookingsModal';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import formatPrice from '../../utils/formatPrice';

interface MatchingPriceTargetErrorModalProps {
  bookings: ValidationErrorBooking[];
}

const MatchingPriceTargetErrorModal = ({
  bookings,
}: MatchingPriceTargetErrorModalProps): JSX.Element => {
  return (
    <BookingsModal heading="Unsuccessful Price Target Match">
      <Table>
        <TableHeader>
          <TableHeaderColumn>Booking ID</TableHeaderColumn>
          <TableHeaderColumn>Listing ID</TableHeaderColumn>
          <TableHeaderColumn>Listing Price</TableHeaderColumn>
        </TableHeader>
        <TableBody>
          {bookings.length === 0 ? (
            <EmptyTableBody />
          ) : (
            bookings.map(
              ({ rowNumber, bookingId, listingId, listingPrice }) => (
                <TableRow key={rowNumber}>
                  <TableContentColumn>{bookingId}</TableContentColumn>
                  <TableContentColumn>
                    <Link
                      href={`https://www.realestate.com.au/${listingId}`}
                      target="_blank"
                    >
                      {listingId}
                    </Link>
                  </TableContentColumn>
                  <TableContentColumn>
                    {formatPrice(listingPrice)}
                  </TableContentColumn>
                </TableRow>
              ),
            )
          )}
        </TableBody>
      </Table>
    </BookingsModal>
  );
};

export default MatchingPriceTargetErrorModal;
