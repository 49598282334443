import { useState, useRef, SyntheticEvent } from 'react';
import { useMutation } from '@tanstack/react-query';
import { fetchJson } from '../API/fetch';
import {
  Stack,
  H1,
  H3,
  P,
  Divider,
  Inline,
  Button,
  spacingHelper,
  paletteHelper,
} from '@construct-kit/core';
import styled from 'styled-components';
import ProcessBookingCampaignPanel from '../components/ProcessBookingCampaignPanel';
import { isPresent } from '../utils/helpers';
import ErrorAlert from '../components/ErrorAlert';
import { ButtonLoadingSpinner } from '../components/LoadingSpinner';

const StyledDivider = styled(Divider)`
  margin: ${spacingHelper('0 0 medium')};
  border-color: ${paletteHelper('borderPrimary')};
`;

const DisabledText = styled(P)`
  color: ${paletteHelper('textDisabled')};
`;

const StyledInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export interface ValidationErrorBooking {
  rowNumber: number;
  bookingId: string;
  listingId: number;
  startDate: string;
  packageId?: string;
  bookingPeriod: number;
  impressions: number;
  listingPrice: number;
  propertyType: string;
  listingPostcode: string;
  agencyId: string;
  customTargeting?: string;
}

export type ValidationErrorType =
  | 'startDate'
  | 'matchingPackage'
  | 'matchingPriceTarget'
  | 'matchingPropertyTypeTarget'
  | 'matchingPostcodeRadiusTarget'
  | 'testAgency'
  | 'customTargeting';

export interface ValidationError {
  message: string;
  validationType: ValidationErrorType;
  bookings: ValidationErrorBooking[];
}

export interface ValidationWarningBooking {
  rowNumber: number;
  bookingId: string;
  listingId: number;
  listingPrice: number;
  propertyType: string;
  impressions: number;
  packageId?: string;
}

export type ValidationWarningType =
  | 'duplicateListingIdCompleted'
  | 'duplicateListingIdInFile'
  | 'duplicateBookingIdCompleted'
  | 'duplicateBookingIdInFile'
  | 'ruralOver3Million'
  | 'dreamHome'
  | 'platinum'
  | 'packageIds'
  | 'stateACT'
  | 'listingNotFound';

export interface Region {
  id: number;
  region: string;
  state: string;
}

export interface PropertyTypeWarningBooking {
  bookingId: string;
  listingId: number;
  propertyType: string;
}

export interface PropertyRegionWarningBooking {
  bookingId: string;
  listingId: number;
  suggestedRegion: Region | null;
}

export interface PropertyTypeWarning {
  message: string;
  bookings: PropertyTypeWarningBooking[];
}
export interface PropertyRegionWarning {
  message: string;
  bookings: PropertyRegionWarningBooking[];
}
export interface ValidationWarning {
  validationType: ValidationWarningType;
  message: string;
  bookings: ValidationWarningBooking[];
}

export interface ValidationErrors {
  type: 'error';
  errors: ValidationError[];
}

export interface ValidationWarnings {
  type: 'warning';
  warnings: ValidationWarning[];
  propertyTypeWarning: PropertyTypeWarning | null;
  propertyRegionWarning: PropertyRegionWarning | null;
}

export type ErrorsOrWarningsOrNull =
  | ValidationErrors
  | ValidationWarnings
  | null;

export interface CreateCampaignResponse {
  bookingCount: number;
  errorsOrWarnings: ErrorsOrWarningsOrNull;
}

const uploadCampaignPostFetchJson = (
  fileData: FormData,
): Promise<CreateCampaignResponse> =>
  fetchJson<CreateCampaignResponse>('/campaign', {
    method: 'POST',
    body: fileData,
    headers: undefined,
  });

const UploadBookingCampaign = (): JSX.Element => {
  const [file, setFile] = useState<string>();
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] =
    useState<boolean>(false);
  const fileInputEl = useRef<HTMLInputElement>(null);

  const {
    mutate,
    isError,
    isPending,
    data: bookingCampaignData,
    error,
    reset,
  } = useMutation({
    mutationFn: uploadCampaignPostFetchJson,
  });

  const submitHandler = (event: SyntheticEvent): void => {
    event.preventDefault();
    if (
      !isPresent(fileInputEl.current) ||
      !isPresent(fileInputEl.current.files)
    ) {
      return;
    }

    setIsUploadButtonDisabled(true);

    const fileData = new FormData();
    fileData.append('file', fileInputEl.current.files[0]);

    mutate(fileData);
  };

  const clearFileInput = (fileInputEl: HTMLInputElement | null): void => {
    if (!isPresent(fileInputEl)) {
      return;
    }
    fileInputEl.value = '';
    setFile(undefined);
  };

  return (
    <Stack gap="medium">
      <Stack gap="large">
        <H1>Bookings Upload</H1>
        <Stack gap="medium">
          <H3>Upload bookings file</H3>
          {isError && <ErrorAlert error={error} />}
          <form onSubmit={submitHandler}>
            <StyledInput
              aria-label="csv file"
              type="file"
              accept=".csv"
              ref={fileInputEl}
              onChange={(e) => {
                setFile(e.target.value !== '' ? e.target.value : undefined);
                setIsUploadButtonDisabled(false);
                reset();
              }}
            />
            <Inline grow={false} justifyContent="space-between">
              <Inline gap="medium" grow={false}>
                <Button
                  variant="outline"
                  sizeVariant="medium"
                  onClick={() => {
                    clearFileInput(fileInputEl.current);
                    fileInputEl.current?.click();
                  }}
                  type="button"
                  aria-hidden={true}
                >
                  Choose file
                </Button>
                {isPresent(file) ? (
                  <P>{file}</P>
                ) : (
                  <DisabledText>No file selected</DisabledText>
                )}
              </Inline>
              <Button
                variant="primary"
                sizeVariant="medium"
                disabled={
                  !isPresent(file) || isUploadButtonDisabled || isPending
                }
                iconPlacement="right"
                icon={isPending ? <ButtonLoadingSpinner /> : undefined}
                type="submit"
              >
                {isPending ? 'Loading' : 'Upload bookings'}
              </Button>
            </Inline>
          </form>
        </Stack>
        <StyledDivider />
      </Stack>
      <ProcessBookingCampaignPanel bookingCampaignData={bookingCampaignData} />
    </Stack>
  );
};

export default UploadBookingCampaign;
