import {
  Button,
  Divider,
  Inline,
  Link,
  Select,
  SelectOption,
  Stack,
  TextArea,
  TextInput,
  paletteHelper,
  spacingHelper,
  typographyHelper,
} from '@construct-kit/core';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { TargetingModRequest } from '../../pages/targetingMods/types';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactNode } from 'react';
import { ButtonLoadingSpinner } from '../LoadingSpinner';
import { isPresent } from '../../utils/helpers';

const Required = styled.span`
  ${typographyHelper('body02')}
  color: ${paletteHelper('textSecondary')};
`;

const RequiredLabel = ({ children }: { children: ReactNode }): JSX.Element => (
  <>
    {children}
    <Required> (required)</Required>
  </>
);

const StyledDivider = styled(Divider)`
  margin: ${spacingHelper('large 0')};
`;

const stateOptions = [
  { value: '', label: 'Any' },
  { value: 'ACT', label: 'ACT' },
  { value: 'NSW', label: 'NSW' },
  { value: 'NT', label: 'NT' },
  { value: 'QLD', label: 'QLD' },
  { value: 'SA', label: 'SA' },
  { value: 'TAS', label: 'TAS' },
  { value: 'VIC', label: 'VIC' },
  { value: 'WA', label: 'WA' },
];

const segmentOptions = [
  { value: 'A', label: '(A)' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'STATE', label: 'State' },
  { value: 'NAT', label: 'National' },
];

const defaultFormValues: TargetingModFormValues = {
  state: '',
  segment: 'A',
  name: '',
  postcodes: '',
  propertyTypes: '',
  priceMin: '',
  priceMax: '',
};

export interface TargetingModFormValues {
  name: string;
  postcodes?: string;
  propertyTypes?: string;
  priceMin?: string;
  priceMax?: string;
  state?: string;
  segment: string;
}

const toTargetingModRequest = ({
  name,
  postcodes,
  propertyTypes,
  priceMin,
  priceMax,
  state,
  segment,
}: TargetingModFormValues): TargetingModRequest => ({
  name: name,
  postcodes: isPresent(postcodes) && postcodes !== '' ? postcodes : null,
  propertyTypes:
    isPresent(propertyTypes) && propertyTypes !== '' ? propertyTypes : null,
  priceMin: isPresent(priceMin) && priceMin !== '' ? parseInt(priceMin) : null,
  priceMax: isPresent(priceMax) && priceMax !== '' ? parseInt(priceMax) : null,
  state: isPresent(state) && state !== '' ? state : null,
  segment: segment,
});

const targetingModFormSchema = object().shape({
  name: string()
    .required('Required.')
    .max(64, 'Must not be longer than 64 characters'),
  postcodes: string()
    .trim()
    .max(2000, 'Must not be longer that 2000 characters'),
  propertyTypes: string()
    .trim()
    .max(256, 'Must not be longer than 256 characters'),
  segment: string().required('Required'),
  priceMin: string()
    .trim()
    .matches(/^([1-9]\d*)?$/, 'Must be a whole number.'),
  priceMax: string()
    .trim()
    .matches(/^([1-9]\d*)?$/, 'Must be a whole number.'),
});

export interface TargetingModFormProps {
  onSave: (values: TargetingModRequest) => void;
  defaultValues?: TargetingModFormValues;
  isLoading: boolean;
}

const TargetingModForm = ({
  onSave,
  defaultValues = defaultFormValues,
  isLoading,
}: TargetingModFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TargetingModFormValues>({
    resolver: yupResolver(targetingModFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const handleSelectChange =
    (name: 'state' | 'segment') => (selectedOption?: SelectOption | null) => {
      setValue(name, selectedOption?.value ?? '');
    };

  const defaultState = stateOptions.find(
    (state) => state.value == defaultValues.state,
  );

  const defaultSegment = segmentOptions.find(
    (segment) => segment.value == defaultValues.segment,
  );

  return (
    <form
      onSubmit={(e) => {
        void handleSubmit((values: TargetingModFormValues) => {
          onSave(toTargetingModRequest(values));
        })(e);
      }}
    >
      <Stack gap="medium">
        <TextInput
          label={<RequiredLabel>Name</RequiredLabel>}
          {...register('name')}
          errorMessage={errors.name?.message?.toString()}
        />
        <TextArea
          label="Postcodes"
          {...register('postcodes')}
          errorMessage={errors.postcodes?.message?.toString()}
        />
        <TextArea
          label="Property Types"
          {...register('propertyTypes')}
          errorMessage={errors.propertyTypes?.message?.toString()}
        />
        <Inline gap="medium" alignItems="start">
          <TextInput
            label="Min Price"
            {...register('priceMin')}
            placeholder="Any"
            errorMessage={errors.priceMin?.message?.toString()}
          />
          <TextInput
            label="Max Price"
            {...register('priceMax')}
            placeholder="Any"
            errorMessage={errors.priceMax?.message?.toString()}
          />
        </Inline>
        <Select
          label="State"
          options={stateOptions}
          defaultSelectedOption={defaultState}
          {...register('state')}
          onSelectedOptionChange={handleSelectChange('state')}
          errorMessage={errors.state?.message?.toString()}
        />
        <Select
          label={<RequiredLabel>Segment</RequiredLabel>}
          options={segmentOptions}
          defaultSelectedOption={defaultSegment}
          {...register('segment')}
          onSelectedOptionChange={handleSelectChange('segment')}
          errorMessage={errors.segment?.message?.toString()}
        />
      </Stack>
      <StyledDivider />
      <Inline gap="medium" justifyContent="end">
        <Link variant="button-outline" as={RouterLink} to="/targeting-mods">
          Cancel
        </Link>
        <Button
          variant="primary"
          type="submit"
          disabled={isLoading}
          iconPlacement="right"
          icon={isLoading ? <ButtonLoadingSpinner /> : undefined}
        >
          {isLoading ? 'Loading' : 'Save'}
        </Button>
      </Inline>
    </form>
  );
};

export default TargetingModForm;
