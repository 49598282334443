import { Link } from '@construct-kit/core';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { fetchJson } from '../../API/fetch';
import { formatDateTime } from '../../utils/formatDate';
import ErrorAlert from '../ErrorAlert';
import Status from '../Status';
import {
  EmptyTableBody,
  Table,
  TableBody,
  TableContentColumn,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from '../Table/Table';
import { isPresent } from '../../utils/helpers';
import { PageLoadingSpinner } from '../LoadingSpinner';

interface CompleteEmailResponse {
  results: BookingsCompleteEmailsFindManyByDateResponse[];
}

interface BookingsCompleteEmailsFindManyByDateResponse {
  id: number;
  bookingId: string;
  status: string;
  emailSent: string | null;
  note: string | null;
  listingId: number;
}

const getBookingCompleteEmails = async (
  date?: string,
): Promise<BookingsCompleteEmailsFindManyByDateResponse[]> => {
  const dateParam = isPresent(date) ? `&date=${date}` : '';
  const { results } = await fetchJson<CompleteEmailResponse>(
    `/email/complete/?list=true${dateParam}`,
  );
  return results;
};

const Container = styled.div`
  min-width: 50rem;
`;

interface BookingCompleteEmailsModalProps {
  date?: string;
}

const BookingCompleteEmailsModal = ({
  date,
}: BookingCompleteEmailsModalProps): JSX.Element => {
  const {
    isLoading,
    isError,
    error,
    data: bookingCompleteEmailsModalData,
  } = useQuery({
    queryKey: ['BookingCompleteEmailsList', date],
    queryFn: () => getBookingCompleteEmails(date),
  });

  return (
    <Container>
      {isLoading && <PageLoadingSpinner />}
      {isError && <ErrorAlert error={error} />}
      {bookingCompleteEmailsModalData && (
        <Table>
          <TableHeader>
            <TableHeaderColumn>Booking ID</TableHeaderColumn>
            <TableHeaderColumn>Status</TableHeaderColumn>
            <TableHeaderColumn>Listing ID</TableHeaderColumn>
            <TableHeaderColumn>Most Recent</TableHeaderColumn>
            <TableHeaderColumn>Note</TableHeaderColumn>
          </TableHeader>
          <TableBody>
            {bookingCompleteEmailsModalData.length === 0 ? (
              <EmptyTableBody />
            ) : (
              bookingCompleteEmailsModalData.map(
                ({ id, bookingId, status, listingId, emailSent, note }) => (
                  <TableRow key={id}>
                    <TableContentColumn>
                      <Link
                        href={`/booking-data?id=${bookingId}`}
                        variant="primary"
                        target="_blank"
                      >
                        {bookingId}
                      </Link>
                    </TableContentColumn>
                    <TableContentColumn>
                      <Status status={status} />
                    </TableContentColumn>
                    <TableContentColumn>{listingId}</TableContentColumn>
                    <TableContentColumn>
                      {formatDateTime(emailSent)}
                    </TableContentColumn>
                    <TableContentColumn noWrap={false}>
                      {note}
                    </TableContentColumn>
                  </TableRow>
                ),
              )
            )}
          </TableBody>
        </Table>
      )}
    </Container>
  );
};
export default BookingCompleteEmailsModal;
