import { Alert, Link } from '@construct-kit/core';
import { Link as RouterLink } from 'react-router-dom';
import { JobProgress } from '../../pages/Home';
import { match } from 'ts-pattern';

interface CampaignUploadStatus {
  bookingUploadStatus: Exclude<JobProgress, JobProgress.NotStarted>;
}

const CampaignUploadStatusAlert = ({
  bookingUploadStatus,
}: CampaignUploadStatus): JSX.Element => {
  return (
    <Alert id="campaign-upload-status" variant="info">
      {match(bookingUploadStatus)
        .with(
          JobProgress.InProgress,
          JobProgress.Error,
          JobProgress.Paused,
          () => 'Bookings are currently being processed. ',
        )
        .with(
          JobProgress.Processed,
          () =>
            'All Bookings have been processed and can be reviewed for completion. ',
        )
        .exhaustive()}
      <Link as={RouterLink} to="/job-queue" variant="inline">
        View bookings
      </Link>
    </Alert>
  );
};

export default CampaignUploadStatusAlert;
