import moment from 'moment';
import { useState } from 'react';
import Panel from './Panel';
import DateRangePicker, { DateRange } from '../calendar/DateRangePicker';
import { Button, Inline, P, Stack } from '@construct-kit/core';
import { downloadFile } from '../../utils/downloadFile';
import { isPresent } from '../../utils/helpers';
import { Nullable } from '../../types/Nullable';

const formatDate = (date: Nullable<moment.Moment>): string =>
  moment(date).format('YYYY-MM-DD');

const PerformanceReportPanel = (): JSX.Element => {
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });

  const downloadReportButtonHandler = (): void => {
    const startDate = formatDate(dateRange.startDate);
    const endDate = formatDate(dateRange.endDate);

    downloadFile(
      `${process.env.API_ROOT}/report/download?startDate=${startDate}&endDate=${endDate}`,
    );
  };

  const isNotValidDateRange =
    !isPresent(dateRange.startDate) || !isPresent(dateRange.endDate);

  return (
    <Panel heading="AMAX Performance Report" showDivider={false}>
      <Stack gap="medium">
        <Stack gap="twoExtraSmall">
          <P variant="subtitle01">Date range</P>
          <Inline gap="extraSmall">
            <DateRangePicker
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
            <Button
              variant="primary"
              sizeVariant="medium"
              disabled={isNotValidDateRange}
              onClick={downloadReportButtonHandler}
            >
              Download report
            </Button>
          </Inline>
        </Stack>
      </Stack>
    </Panel>
  );
};

export default PerformanceReportPanel;
